// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {WCtuk6nCs: {hover: true}};

const cycleOrder = ["WCtuk6nCs"];

const variantClassNames = {WCtuk6nCs: "framer-v-8tneji"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "WCtuk6nCs", image: vHE_IpTo4 = {src: new URL("assets/wxpqirTB5LsBRgNqfd0fSln4.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/wxpqirTB5LsBRgNqfd0fSln4.jpg", import.meta.url).href} 512w, ${new URL("assets/wxpqirTB5LsBRgNqfd0fSln4.jpg", import.meta.url).href} 600w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WCtuk6nCs", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-GzTao", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-8tneji", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WCtuk6nCs"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"WCtuk6nCs-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 565, intrinsicWidth: 600, pixelHeight: 565, pixelWidth: 600, ...toResponsiveImage_194x2gw(vHE_IpTo4)}} className={"framer-5b8z3o"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"wDBw5cGYq"} style={{filter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}} transition={transition} variants={{"WCtuk6nCs-hover": {filter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-GzTao [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GzTao * { box-sizing: border-box; }", ".framer-GzTao .framer-fzv08c { display: block; }", ".framer-GzTao .framer-8tneji { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 1080px; }", ".framer-GzTao .framer-5b8z3o { aspect-ratio: 1.0636079249217936 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 959px); overflow: hidden; position: relative; width: 1px; }", ".framer-GzTao .framer-v-8tneji .framer-8tneji { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-GzTao .framer-8tneji { gap: 0px; } .framer-GzTao .framer-8tneji > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-GzTao .framer-8tneji > :first-child { margin-left: 0px; } .framer-GzTao .framer-8tneji > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1019
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"lrvxp0lHg":{"layout":["fixed","auto"]}}}
 * @framerVariables {"vHE_IpTo4":"image"}
 */
const FramerhHkm4gftJ: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerhHkm4gftJ;

FramerhHkm4gftJ.displayName = "Mine what image 2";

FramerhHkm4gftJ.defaultProps = {height: 1019, width: 1080};

addPropertyControls(FramerhHkm4gftJ, {vHE_IpTo4: {__defaultAssetReference: "data:framer/asset-reference,wxpqirTB5LsBRgNqfd0fSln4.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerhHkm4gftJ, [])